// необязательные поля для нового события
export const typesFields = {
  filling: ['datetime', 'deviation_minutes', 'filled', 'price', 'description'],
  theft: ['datetime', 'deviation_minutes', 'stolen', 'description'],
  accident: ['datetime', 'deviation_minutes', 'description'],
  service: [
    'datetime',
    'deviation_minutes',
    'service_name',
    'motohours',
    'millage',
    'duration_hours',
    'price',
    'guaranteed',
    'description',
  ],
  waybill: [
    'start_date',
    'finish_date',
    'user_id',
    'motohours',
    'millage',
    'filled',
    'consumed',
  ],
  random: [
    'datetime',
    'deviation_minutes',
    'type_name',
    'duration_minutes',
    'random_duration_hours',
    'description',
  ],
  vehicle_status: ['datetime', 'type_name', 'description'],
}

// обязательные поля для создания нового события
export const typesRequiredFields = {
  filling: ['datetime', 'deviation_minutes', 'filled'],
  theft: ['datetime', 'deviation_minutes', 'stolen'],
  accident: ['datetime', 'deviation_minutes'],
  service: ['datetime', 'deviation_minutes', 'service_name'],
  waybill: ['start_date', 'finish_date'],
  random: ['datetime', 'deviation_minutes', 'type_name'],
  vehicle_status: ['datetime', 'type_name'],
}
