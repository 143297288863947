import L from '@/utils/leaflet.js'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('routes', {
      pointsToDraw: (state) => state.pointsToDraw,
      drawenGeo: (state) => state.drawenGeo,
      pointsToDelete: (state) => state.pointsToDelete,
      oldpointsToDelete: (state) => state.oldpointsToDelete,
      pointsToClean: (state) => state.pointsToClean,
      removeLastPoint: (state) => state.removeLastPoint,
      geoToEdit: (state) => state.geoToEdit
    }),
    ...mapState('races', {
      endEdit: (state) => state.endEdit
    })
  },
  watch: {
    endEdit() {
      if (this.endEdit === true) {
        this.geoEdited.disableEdit()
        this.geoEdited.removeFrom(this.map)
      } else if (this.endEdit === false) {
        this.geoEdited.addTo(this.map)
        this.geoEdited.enableEdit()
      }
      // this.endEdit === true
      //   ? this.geoEdited.disableEdit() &&
      //     this.$store.dispatch('races/END_EDITING', false)
      //   : (this.endEdit = false)
    },
    geoToEdit(geo) {
      // if (this.geoToEdit.type.key === 'point') {
      //   this.geoToEdit.geometry = this.geoToEdit.geometry.flat()
      // }
      const formattedGeo = this.formatGeo(this.geoToEdit)

      this.map.addLayer(formattedGeo)
      formattedGeo.enableEdit()
      formattedGeo.unbindTooltip()
      if (formattedGeo.options.shape === 'point') {
        formattedGeo.on('editable:dragend', (e) => {
          // const coords = e.layer.getLatLng()
          // let LatLng = [coords.lat, coords.lng]
          this.$store.commit('races/SET_GEOZONE_COORDS', {
            latlng: [formattedGeo[0], formattedGeo[1]]
          })
        })
      } else {
        formattedGeo.on('editable:vertex:dragend', (e) => {
          switch (e.layer.options.shape) {
            case 'polygon':
              this.$store.commit('races/SET_GEOZONE_COORDS', {
                latlng: e.layer
                  .getLatLngs()
                  .map((coords) => [coords.lat, coords.lng])
              })
              // console.log('polygon edited latlng', e.layer.getLatLngs)
              break
            case 'circle':
              const coords = e.layer.getLatLng()
              const radius = e.layer.getRadius()
              console.log('circle after edit ', radius, coords)
              this.$store.commit('races/SET_GEOZONE_COORDS', {
                latlng: [coords.lat, coords.lng],
                radius: e.layer.getRadius()
              })
              break
            case 'line':
              this.$store.commit('races/SET_GEOZONE_COORDS', {
                latlng: e.layer
                  .getLatLngs()
                  .map((coords) => [coords.lat, coords.lng])
              })
              break
            default:
              break
          }
        })
      }
      this.geoEdited = formattedGeo
      formattedGeo.options.shape === 'polygon'
        ? this.map.panTo(formattedGeo.getBounds().getCenter())
        : formattedGeo.options.shape === 'circle'
        ? this.map.panTo(formattedGeo.getLatLng())
        : formattedGeo.options.shape === 'line'
        ? this.map.panTo(formattedGeo.getBounds().getCenter())
        : this.map.panTo(formattedGeo._latlng)
    },

    removeLastPoint() {
      this.removeMark(this.geozoneToDelete)
    },
    pointsToClean(value) {
      const formattedGeo = []
      if (value.points !== undefined) {
        value.points.forEach((el) => {
          formattedGeo.push(this.formatGeo(el))
        })
        this.removeMark(this.geozonesToDelete)
      }
    },
    pointsToDraw(value) {
      // console.log('correct value type', value)
      const pointsArr = []
      value.points.forEach((point) => pointsArr.push(point.geometry))
      const bounds = L.latLngBounds(pointsArr)
      //  let layerGroup = L.layerGroup(this.geozonesToDelete)
      this.removeMark(this.geozonesToDelete)
      this.geozonesToDelete = []
      this.showAllGeozones(value)
      this.map.fitBounds(bounds, { paddingTopLeft: [300, 300] })
    },
    drawenGeo(value) {
      this.removeMark(this.geozoneToDelete)
      this.showGeozone(value)
    },
    oldpointsToDelete(geozones) {
      const formattedGeozones = []
      geozones.forEach((geo) => {
        formattedGeozones.push(this.formatGeo(geo))
      })
      formattedGeozones.forEach((geo) => {
        geo.removeFrom(this.map)
      })
    },
    pointsToDelete(geozones) {
      const { geozoneToDelete } = this
      let pointToRemove = geozoneToDelete.filter(function (o) {
        return geozones.some(function (o2) {
          return o.id === o2.id
        })
      })
      // console.log('common', pointToRemove)
      pointToRemove.forEach((point) => {
        console.log('slick 2', point)
        if (this.map.hasLayer(point)) {
          //  console.log('correct point', point)
          point.removeFrom(this.map)
        }
      })
      pointToRemove = []
      // this.geozonesToDelete
    }
  },
  data() {
    return {
      drawenGeozonesTrips: [],
      geozonesToDelete: [],
      geozoneToDelete: [],
      drawingGeo: null,
      geozoneParams: {
        shape: 'polygon',
        type: 'fillingstation',
        color: '#F56C6C',
        width: '1'
      },
      geoId: null,
      geoEdited: null
    }
  },
  methods: {
    formatGeo(geozone) {
      if (geozone.geometry && geozone.geometry.length !== 0) {
        let format
        switch (geozone.type.key) {
          case 'polygon':
            const polygon = L.polygon(geozone.geometry, {
              color: 'blue',
              opacity: 0.3,
              type: geozone.type.key
            })
            polygon.bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-blue',
              direction: 'bottom'
            })
            polygon.options.shape = 'polygon'
            // component.addLabel(polygon, this.GenerateUniqueID())
            polygon.id = geozone.id
            format = polygon
            break
          case 'circle':
            const circle = L.circle(geozone.geometry[0], {
              radius: geozone.width,
              color: 'blue',
              weight: 1,
              type: geozone.type.key
            }).bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-blue',
              direction: 'bottom'
            })

            circle.options.shape = 'circle'

            circle.id = geozone.id
            format = circle
            break
          case 'line':
            const line = L.polyline(geozone.geometry, {
              color: 'blue',
              weight: geozone.width || 1,
              type: geozone.type.key
            }).bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-blue',
              direction: 'bottom'
            })

            line.options.shape = 'line'

            line.id = geozone.id
            format = line
            break
          case 'point':
            const marker = L.marker(geozone.geometry[0], {
              icon: L.icon({
                iconUrl: `/static/markers/airport_blue.svg`,
                iconSize: [26, 36],
                iconAnchor: [13, 36]
              }),
              type: geozone.type.key,
              color: 'blue'
            }).bindTooltip(geozone.name, {
              pane: 'geozonenamePane',
              permanent: true,
              className: 'geozone-label-blue',
              direction: 'bottom'
            })

            marker.options.shape = 'point'
            marker.id = geozone.id
            format = marker
            break
          default:
            break
        }
        return format
      }
    },
    prepareOldPoints(geozones) {
      console.log('preparing ...', geozones)
    },
    GenerateUniqueID() {
      return (Math.random() * (78500000 - 78400101) + 78400101) | 0
    },
    removeMark(geozonesToDelete) {
      geozonesToDelete.forEach((geo) => {
        if (this.map.hasLayer(geo)) {
          geo.removeFrom(this.map)
        }
      })
    },
    showGeozone(geozone) {
      // const component = this
      if (geozone.geometry && geozone.geometry.length !== 0) {
        switch (geozone.type.key) {
          case 'polygon':
            const polygon = L.polygon(geozone.geometry, {
              color: 'red',
              opacity: 0.3,
              type: geozone.type.key
            })
            polygon
              .bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })

              .addTo(this.map)
            //  this.map.panTo(polygon.geometry.getLatLng())

            polygon.options.shape = 'polygon'
            // component.addLabel(polygon, this.GenerateUniqueID())
            this.drawenGeozonesTrips = {
              ...this.drawenGeozonesTrips,
              [geozone.id]: polygon
            }
            this.map.panTo(polygon.getBounds().getCenter())
            polygon.id = geozone.id
            this.geozoneToDelete.push(polygon)
            break
          case 'circle':
            const circle = L.circle(geozone.geometry[0], {
              radius: geozone.width,
              color: 'red',
              weight: 1,
              type: geozone.type.key
            })
              .bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })
              .addTo(this.map)
            // component.addLabel(circle, this.GenerateUniqueID())
            circle.options.shape = 'circle'
            this.drawenGeozonesTrips = {
              ...this.drawenGeozonesTrips,
              [geozone.id]: circle
            }
            this.map.panTo(circle.getLatLng())
            circle.id = geozone.id
            this.geozoneToDelete.push(circle)
            break
          case 'line':
            const line = L.polyline(geozone.geometry, {
              color: 'red',
              weight: geozone.width || 1,
              type: geozone.type.key
            })
              .bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })
              .addTo(this.map)
            line.options.shape = 'line'
            // component.addLabel(line, this.GenerateUniqueID())
            this.drawenGeozonesTrips = {
              ...this.drawenGeozonesTrips,
              [geozone.id]: line
            }
            this.map.panTo(line.getBounds().getCenter())
            line.id = geozone.id
            this.geozoneToDelete.push(line)
            break
          case 'point':
            // const marker = L.marker(geozone.geometry[0], {
            //   icon: L.icon({
            //     iconUrl: `/static/markers/airport_blue.svg`,
            //     iconSize: [26, 36],
            //     iconAnchor: [13, 36]
            //   }),
            //   type: geozone.type.key,
            //   color: 'blue'
            // })
            //   .bindTooltip(geozone.name, {
            //     pane: 'geozonenamePane',
            //     permanent: true,
            //     className: 'geozone-label-blue',
            //     direction: 'bottom'
            //   })
            //   .addTo(this.map)
            const marker = L.marker(geozone.geometry[0], {
              iconUrl: 'images/marker-icon.png',
              shadowUrl: 'images/marker-shadow.png'
            }).addTo(this.map)
            marker.options.shape = 'point'
            // component.addLabel(marker, this.GenerateUniqueID())
            this.drawenGeozonesTrips = {
              ...this.drawenGeozonesTrips,
              [geozone.id]: marker
            }
            console.log('marker', marker)

            this.map.panTo(marker.getLatLng())
            marker.id = geozone.id
            this.geozoneToDelete.push(marker)
            break
          default:
            break
        }
      }
    },
    showAllGeozones(geozonesToDraw) {
      console.log(1)
      geozonesToDraw.points.forEach((geozone) => {
        if (geozone.geometry) {
          let drawnGeozone

          switch (geozone.type.key) {
            case 'polygon':
              drawnGeozone = L.polygon(geozone.geometry, {
                color: 'red',
                opacity: 0.3,
                type: geozone.type.key
              }).addTo(this.map)
              drawnGeozone.bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })
              drawnGeozone.options.shape = 'polygon'
              break

            case 'circle':
              drawnGeozone = L.circle(geozone.geometry[0], {
                radius: geozone.width,
                color: 'red',
                weight: 1,
                type: geozone.type.key
              }).addTo(this.map)
              drawnGeozone.bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })
              drawnGeozone.options.shape = 'circle'
              break

            case 'line':
              drawnGeozone = L.polyline(geozone.geometry, {
                color: 'red',
                weight: geozone.width || 1,
                type: geozone.type.key
              }).addTo(this.map)
              drawnGeozone.bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })
              drawnGeozone.options.shape = 'line'
              break

            case 'point':
              drawnGeozone = L.marker(geozone.geometry.flat(), {
                icon: L.icon({
                  iconUrl: `/static/markers/airport_blue.svg`,
                  iconSize: [26, 36],
                  iconAnchor: [13, 36]
                }),
                type: geozone.type.key,
                color: 'red'
              }).addTo(this.map)
              drawnGeozone.bindTooltip(geozone.name, {
                pane: 'geozonenamePane',
                permanent: true,
                className: 'geozone-label-red',
                direction: 'bottom'
              })
              drawnGeozone.options.shape = 'point'
              break

            default:
              break
          }

          if (drawnGeozone) {
            this.drawenGeozonesTrips = {
              ...this.drawenGeozonesTrips,
              [geozone.id]: drawnGeozone
            }
            this.geozonesToDelete.push(drawnGeozone)
          }
        }
      })
    }
  }
}
