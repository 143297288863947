import { mapState } from 'vuex'
import L from '@/utils/leaflet.js'
import { numberFormat } from './leaflet-measure/utils'
import calc from './leaflet-measure/calc'
import 'leaflet-draw'
// import Length from '@turf/length'
// import turf from 'turf'

export default {
  computed: {
    ...mapState('measure', {
      isMeasureStarted: (state) => state.isMeasureStarted,
      isMeasureFinished: (state) => state.isMeasureFinished,
      typeMeasure: (state) => state.typeMeasure,
      isCanceled: (state) => state.isCanceled
    }),
    ...mapState('map', {
      eyeTriggered: (state) => state.eyeTriggered.value
    })
  },
  data() {
    return {
      result: '',
      results: [],
      options: {
        units: {
          kilometers: {
            factor: 0.001,
            display: 'kilometers',
            decimals: 2
          },
          hectares: {
            factor: 0.0001,
            display: 'hectares',
            decimals: 2
          },
          meters: {
            factor: 1,
            display: 'meters',
            decimals: 0
          },
          sqmeters: {
            factor: 1,
            display: 'sqmeters',
            decimals: 0
          }
        },
        position: 'topright',
        primaryLengthUnit: 'meters',
        secondaryLengthUnit: 'kilometers',
        primaryAreaUnit: 'sqmeters',
        secondaryAreaUnit: 'hectares',
        activeColor: '#1BB934',
        completedColor: '#1BB934',
        captureZIndex: 10000,
        popupOptions: {
          // standard leaflet popup options http://leafletjs.com/reference-1.3.0.html#popup-option
          className: 'leaflet-measure-resultpopup',
          autoPanPadding: [10, 10]
        }
      },
      mlayer: null,
      measureDrag: null,
      captureMarker: null,
      measureArea: null,
      measureBoundary: null,
      measureVertexes: null,
      mlatlngs: [],
      resultModel: null,
      calced: '',
      // eslint-disable-next-line new-cap
      FeatureGroup: new L.FeatureGroup(),
      currentLayer: '',
      unitDefinitions: {},
    }
  },
  watch: {
    isCanceled(val) {
      this.map.editTools.stopDrawing()
      if (this.isMeasureStarted) {
        const { map } = this
        this.map.on('editable:drawing:cancel', function (e) {
          map.removeLayer(e.layer)
        })
        this.$store.commit('measure/RESULT_MODEL', true)
        this.$store.commit('measure/RESET')

        this.startMeasure()
      }

      // });
    },
    typeMeasure(val) {
      this.startMeasure(val)
    },
    eyeTriggered(val) {
      if (val === 'hideMeasure') {
        this.removeAllResults()
        this.$store.commit('map/EYE_TRIGGER', 'miz')
      } else {
        this.resetMeasure()
      }
    },
    isMeasureStarted(val) {
      if (val) {
        this.startMeasure()
      } else {
        this.map.editTools.stopDrawing()
        console.log('measure finished')
        this.$store.commit('measure/RESULT_MODEL', false)
        this.$store.commit('measure/HANDLE_MEASURETYPE', 'polygon')
      }
    },
    isMeasureFinished() {
      this.handleMeasureDoubleClick()
    }
  },
  methods: {
    formatMeasure(val, unit, decPoint, thousandsSep) {
      const unitDisplays = {
        acres: this.$t('unitDisplays.format.acres'),
        feet: this.$t('unitDisplays.format.feet'),
        kilometers: this.$t('unitDisplays.format.kilometers'),
        hectares: this.$t('unitDisplays.format.hectares'),
        meters: this.$t('unitDisplays.format.meters'),
        miles: this.$t('unitDisplays.format.miles'),
        sqfeet: this.$t('unitDisplays.format.sqfeet'),
        sqmeters: this.$t('unitDisplays.format.sqmeters'),
        sqmiles: this.$t('unitDisplays.format.sqmiles'),
      }
    
      const u = L.extend({ factor: 1, decimals: 0 }, unit)
      const formattedNumber = numberFormat(
        val * u.factor,
        u.decimals,
        decPoint || '.',
        thousandsSep || ','
      )
      const label = unitDisplays[u.display] || u.display
      return [formattedNumber, label].join(' ')
    },
    showAreasPopus(e) {
      this._console('showAreasPopus')
      this.results.forEach((res) => {
        if (res.getBounds().contains(e.latlng)) {
          const popup = res.getPopup()
          popup.openOn(this.map)
        }
      })
    },
    removeAllResults() {
      this.results.forEach((res) => {
        if (res && res.removeFrom) {
          res.removeFrom(this.map)
        }
      })
      this.results = []
    },
    resetMeasure() {
      this.$store.commit('map/EYE_TRIGGER', '')
    },
    startMeasure(typeOfShape) {
      this.FeatureGroup.removeFrom(this.map)
      this.FeatureGroup.clearLayers()
      this.$store.commit('measure/RESULT_MODEL', true)
      if (typeOfShape === 'polygon' || typeOfShape === undefined) {
        this.FeatureGroup.addTo(this.map)
        this.map.editTools
          .startPolygon('', {
            radius: 10,
            color: this.options.activeColor,
            weight: 5,
            opacity: 0.6,
            fillColor: this.options.activeColor,
            fillOpacity: 0.4,
            className: 'layer-measure-resultpoint'
          })
          .on('editable:drawing:clicked', (e) => {
            const latlngs = e.layer.getLatLngs()[0]
            const calced = calc(latlngs)
            this.calced = this.getMeasurementDisplayStrings(calced)
            this.$store.commit('measure/RESULT_MODEL', this.calced)
          })
          .on('editable:vertex:dragend', (e) => {
            const latlngs = e.layer.getLatLngs()[0]
            this.calced = calc(latlngs)

            const calced = calc(latlngs)
            this.calced = this.getMeasurementDisplayStrings(calced)
            this.$store.commit('measure/RESULT_MODEL', this.calced)
          })
          .on('editable:drawing:end', (e) => {})
          .on('editable:vertex:click', (e) => {
            const latlngs = e.layer.getLatLngs()[0]
            if (
              e.latlng.lat === latlngs[0].lat &&
              e.latlng.lng === latlngs[0].lng
            ) {
              console.log('*************** Привет Скиф')
              let previousPoint
              const latlngD = []
              latlngs.forEach(function (latLng) {
                if (previousPoint) {
                  const d = previousPoint.distanceTo(latLng)
                  latlngD.push(d)
                }
                previousPoint = latLng
              })
              const lastDistance = latlngs[latlngs.length - 1].distanceTo(
                e.latlng
              )
              if (latlngD.length >= 2) {
                latlngD.push(latlngD[0])
                latlngD.push(lastDistance)
                // let perimeter = latlngD.reduce((a, b) => a + b)

                this.calced = {
                  ...this.calced,
                  perimeter: this.calced.lengthDisplay
                }
                this.$store.commit('measure/RESULT_MODEL', this.calced)
                const popupContent = this.areaPopupTemplateCompiled({
                  model: L.extend(
                    {},
                    this.calced,
                    this.getMeasurementDisplayStrings(this.calced)
                  )
                })

                const polygon = L.polygon(latlngs, {
                  clickable: true,
                  radius: 2,
                  color: this.options.completedColor,
                  weight: 4,
                  opacity: 0.4,
                  fillColor: this.options.completedColor,
                  fillOpacity: 0.4,
                  className: 'layer-measure-resultpoint'
                })

                this.FeatureGroup.addLayer(polygon)
                polygon
                  .bindPopup(popupContent, {
                    closeOnClick: false,
                    closeButton: false
                  })
                  .openPopup()
                e.layer.disableEdit()
                e.layer.removeFrom(this.map)
              }
            } else {
              console.log('not true')
              const latlngs = e.layer.getLatLngs()[0]
              let previousPoint
              const latlngD = []
              latlngs.forEach(function (latLng) {
                if (previousPoint) {
                  const d = previousPoint.distanceTo(latLng)
                  latlngD.push(d)
                }
                previousPoint = latLng
              })
              if (latlngD.length >= 2) {
                latlngD.push(latlngD[0])
                const perimeter = latlngD.reduce((a, b) => a + b)
                this.calced = { ...this.calced, perimeter }
              }

              const popupContent = this.areaPopupTemplateCompiled({
                model: L.extend(
                  {},
                  this.calced,
                  this.getMeasurementDisplayStrings(this.calced)
                )
              })

              const polygon = L.polygon(latlngs, {
                clickable: true,
                radius: 2,
                color: this.options.completedColor,
                weight: 4,
                opacity: 0.4,
                fillColor: this.options.completedColor,
                fillOpacity: 0.4,
                className: 'layer-measure-resultpoint'
              })

              this.FeatureGroup.addLayer(polygon)
              polygon
                .bindPopup(popupContent, {
                  closeOnClick: false,
                  closeButton: false
                })
                .openPopup()
              e.layer.disableEdit()
              e.layer.removeFrom(this.map)
              // this.currentLayer = polygon
            }
          })
      } else {
        const { map } = this
        this.map.on('editable:drawing:cancel', function (e) {
          map.removeLayer(e.layer)
        })

        this.map.editTools
          .startPolyline('', {
            radius: 10,
            color: this.options.activeColor,
            weight: 5,
            opacity: 0.6,
            fillColor: this.options.activeColor,
            fillOpacity: 0.4,
            className: 'layer-measure-resultpoint'
          })
          .on('editable:drawing:clicked', (e) => {
            const latlngs = e.layer.getLatLngs()
            const calced = calc(latlngs)

            this.calced = {
              ...this.getMeasurementDisplayStrings(calced),
              isLine: true
            }
            this.$store.commit('measure/RESULT_MODEL', this.calced)
            // this.calced = this.getMeasurementDisplayStrings(calced)
            // this.$store.commit('measure/RESULT_MODEL', this.calced)
          })
          .on('editable:vertex:dragend', (e) => {
            const latlngs = e.layer.getLatLngs()
            this.calced = calc(latlngs)

            const calced = calc(latlngs)
            this.calced = {
              ...this.getMeasurementDisplayStrings(calced),
              isLine: true
            }
            this.$store.commit('measure/RESULT_MODEL', this.calced)
          })
          .on('editable:vertex:click', (e) => {
            const latlngs = e.layer.getLatLngs()
            const calced = calc(latlngs)
            this.calced = {
              ...this.getMeasurementDisplayStrings(calced),
              isLine: true
            }

            const polygon = L.polyline(latlngs, {
              clickable: true,
              radius: 2,
              color: this.options.completedColor,
              weight: 4,
              opacity: 0.4,
              fillColor: this.options.completedColor,
              fillOpacity: 0.4,
              className: 'layer-measure-resultpoint'
            })
            const popupContent = this.linePopupTemplateCompiled(this.calced)
            this.FeatureGroup.addLayer(polygon)
            this.FeatureGroup.addTo(this.map)
            polygon
              .bindPopup(popupContent, {
                closeOnClick: false,
                closeButton: false
              })
              .openPopup()
            e.layer.disableEdit()
            e.layer.removeFrom(this.map)
          })
      }
    },
    finishMeasure() {
      // const model = L.extend({}, this.resultsModel, { points: this.mlatlngs })

      this.clearMeasure()

      this.$store.commit('measure/RESULT_MODEL', null)
      this.$emit('closeMeasure')

      this.captureMarker
        .off('mouseout', this.handleMapMouseOut, this)
        .off('click', this.handleMeasureClick, this)
        .off('dblclick', this.handleMeasureDoubleClick, this)

      this.map
        .off('mousemove', this.handleMeasureMove, this)
        .off('mouseout', this.handleMapMouseOut, this)
        .off('move', this.centerCaptureMarker, this)
        .off('resize', this.setCaptureMarkerIcon, this)

      this.mlayer
        .removeLayer(this.measureVertexes)
        .removeLayer(this.captureMarker)
      this.measureVertexes = null
    },
    clearMeasure() {
      this._console('clearMeasure')
      this.mlatlngs = []
      this.resultsModel = null
      this.measureVertexes.clearLayers()
      if (this.measureDrag) {
        this.mlayer.removeLayer(this.measureDrag)
      }
      if (this.measureArea) {
        this.mlayer.removeLayer(this.measureArea)
      }
      if (this.measureBoundary) {
        this.mlayer.removeLayer(this.measureBoundary)
      }
      this.measureDrag = null
      this.measureArea = null
      this.measureBoundary = null
    },
    centerCaptureMarker() {
      this.captureMarker.setLatLng(this.map.getCenter())
    },
    handleMeasureClick(evt) {
      this._console('handleMeasureClick')
      const latlng = this.map.mouseEventToLatLng(evt.originalEvent)
      const lastClick = this.mlatlngs[this.mlatlngs.length - 1]
      const vertexSymbol = {
        clickable: true,
        radius: 4,
        color: this.options.activeColor,
        weight: 2,
        opacity: 1,
        fillColor: this.options.activeColor,
        fillOpacity: 0.7,
        className: 'layer-measurevertex'
      }
      if (!lastClick || !latlng.equals(lastClick)) {
        this.mlatlngs.push(latlng)
        this.addMeasureArea(this.mlatlngs)
        this.addMeasureBoundary(this.mlatlngs)

        this.measureVertexes.eachLayer(function (layer) {
          layer.setStyle(vertexSymbol)

          if (layer._path) {
            layer._path.setAttribute('class', vertexSymbol.className)
          }
        })

        this.addNewVertex(latlng)

        if (this.measureBoundary) {
          this.measureBoundary.bringToFront()
        }
        this.measureVertexes.bringToFront()
      }
      this.updateResults()
    },
    updateResults() {
      const calced = calc(this.mlatlngs)
      const model = (this.resultsModel = L.extend(
        {},
        calced,
        this.getMeasurementDisplayStrings(calced),
        {
          pointCount: this.mlatlngs.length
        }
      ))
      this.$store.commit('measure/RESULT_MODEL', model)
    },
    getMeasurementDisplayStrings(measurement) {
      this.unitDefinitions = this.options.units

      return {
        lengthDisplay: this.buildDisplay(
          measurement.length,
          this.options.primaryLengthUnit,
          this.options.secondaryLengthUnit,
          this.options.decPoint,
          this.options.thousandsSep
        ),
        areaDisplay: this.buildDisplay(
          measurement.area,
          this.options.primaryAreaUnit,
          this.options.secondaryAreaUnit,
          this.options.decPoint,
          this.options.thousandsSep
        )
      }
    },

    buildDisplay(
      val,
      primaryUnit,
      secondaryUnit,
      decPoint,
      thousandsSep
    ) {
      if (primaryUnit && this.unitDefinitions[primaryUnit]) {
        let display = this.formatMeasure(
          val,
          this.unitDefinitions[primaryUnit],
          decPoint,
          thousandsSep
        )
        if (secondaryUnit && this.unitDefinitions[secondaryUnit]) {
          const formatted = this.formatMeasure(
            val,
            this.unitDefinitions[secondaryUnit],
            decPoint,
            thousandsSep
          )
          display = `${display} (${formatted})`
        }
        return display
      }
      return this.formatMeasure(val, null, decPoint, thousandsSep)
    },

    addNewVertex(latlng) {
      L.circleMarker(latlng, {
        clickable: false,
        radius: 4,
        color: this.options.activeColor,
        weight: 2,
        opacity: 1,
        fillColor: this.options.activeColor,
        fillOpacity: 1,
        className: 'layer-measurevertex active'
      }).addTo(this.measureVertexes)
    },
    handleMeasureDoubleClick() {
      console.log('discour', this.measureVertexes)
      this._console('handleMeasureDoubleClick')
      const latlngs = this.mlatlngs
      let resultFeature
      let popupContent
      this.$store.commit('measure/MEASURE_CHANGE', false)

      if (!latlngs.length) {
        return
      }

      if (latlngs.length > 2) {
        latlngs.push(latlngs[0]) // close path to get full perimeter measurement for areas
      }

      const calced = calc(latlngs)

      if (latlngs.length === 1) {
        resultFeature = L.circleMarker(latlngs[0], {
          clickable: true,
          radius: 4,
          color: this.options.completedColor,
          weight: 2,
          opacity: 1,
          fillColor: this.options.completedColor,
          fillOpacity: 0.7,
          className: 'layer-measure-resultpoint'
        })
        popupContent = this.pointPopupTemplateCompiled({
          model: calced
        })
      } else if (latlngs.length === 2) {
        resultFeature = L.polyline(latlngs, {
          clickable: true,
          color: this.options.completedColor,
          weight: 3,
          opacity: 0.9,
          fill: false,
          className: 'layer-measure-resultline'
        })
        popupContent = this.linePopupTemplateCompiled({
          model: L.extend({}, calced, this.getMeasurementDisplayStrings(calced))
        })
      } else {
        resultFeature = L.polygon(latlngs, {
          clickable: true,
          color: this.options.completedColor,
          weight: 2,
          opacity: 0.9,
          fillColor: this.options.completedColor,
          fillOpacity: 0.2,
          className: 'layer-measure-resultarea'
        })

        popupContent = this.areaPopupTemplateCompiled({
          model: L.extend({}, calced, this.getMeasurementDisplayStrings(calced))
        })
        // let comp = this
        // this.map.on('editable:vertex:dragend', function(e) {
        //   comp.updateResults()
        // })
      }
      resultFeature.addTo(this.mlayer)

      resultFeature.bindPopup(popupContent, this.options.popupOptions)
      if (resultFeature.getBounds) {
        resultFeature.openPopup(resultFeature.getBounds().getCenter())
      } else if (resultFeature.getLatLng) {
        resultFeature.openPopup(resultFeature.getLatLng())
      }
      this.result = resultFeature
      this.results.push(resultFeature)
    },
    addMeasureBoundary(latlngs) {
      if (latlngs.length < 2) {
        if (this.measureBoundary) {
          this.mlayer.removeLayer(this.measureBoundary)
          this.measureBoundary = null
        }
        return
      }
      if (!this.measureBoundary) {
        this.measureBoundary = L.polyline(latlngs, {
          clickable: false,
          color: this.options.activeColor,
          weight: 2,
          opacity: 0.9,
          fill: false,
          className: 'layer-measureboundary'
        }).addTo(this.mlayer)
      } else {
        this.measureBoundary.setLatLngs(latlngs)
      }
    },
    addMeasureArea(latlngs) {
      if (latlngs.length < 3) {
        if (this.measureArea) {
          this.mlayer.removeLayer(this.measureArea)
          this.measureArea = null
        }
        return
      }
      if (!this.measureArea) {
        this.measureArea = L.polygon(latlngs, {
          clickable: false,
          stroke: false,
          fillColor: this.options.activeColor,
          fillOpacity: 0.2,
          className: 'layer-measurearea'
        }).addTo(this.mlayer)
      } else {
        this.measureArea.setLatLngs(latlngs)
      }
    },
    setCaptureMarkerIcon() {
      this.captureMarker.setIcon(
        L.divIcon({
          iconSize: this.map.getSize().multiplyBy(2)
        })
      )
    },
    handleMapMouseOut() {
      if (this.measureDrag) {
        this.mlayer.removeLayer(this.measureDrag)
        this.measureDrag = null
      }
    },
    handleMeasureMove(evt) {
      if (!this.measureDrag) {
        this.measureDrag = L.circleMarker(evt.latlng, {
          clickable: false,
          radius: 4,
          color: this.options.activeColor,
          weight: 2,
          opacity: 0.7,
          fillColor: this.options.activeColor,
          fillOpacity: 0.5,
          className: 'layer-measuredrag'
        }).addTo(this.mlayer)
      } else {
        this.measureDrag.setLatLng(evt.latlng)
      }
      this.measureDrag.bringToFront()
    },
    pointPopupTemplateCompiled({ model }) {
      return `<div><div class="font-SourceSansPro font-semibold mt-5 pb-4 text-textmain border-b">Местоположение точки</div>
<p class="text-someGrey font-semibold text-xs">${
        model.lastCoord.dms.y
      } <span>/</span> ${model.lastCoord.dms.x}</p>
<p class="text-someGrey font-semibold text-xs">${numberFormat(
        model.lastCoord.dd.y,
        6
      )} <span>/</span> ${numberFormat(model.lastCoord.dd.x, 6)}</p>
</div>`
    },
    linePopupTemplateCompiled(model) {
      const container = document.createElement('div')
      const measureDiv = document.createElement('div')
      measureDiv.classList.add(
        'font-SourceSansPro',
        'font-semibold',
        'mt-5',
        'pb-4',
        'text-textmain',
        'text-textmain',
        'border-b'
      )
      measureDiv.innerText = this.$t('linear.measurement')
      container.appendChild(measureDiv)
      const p = document.createElement('p')
      p.innerText = model.lengthDisplay
      p.classList.add('text-someGrey', 'text-xs', 'font-semibold')
      container.appendChild(p)
      const clearBtn = document.createElement('button')
      clearBtn.innerText = this.$t('measure.clear')
      clearBtn.classList.add(
        'focus_outline-none',
        'font-SourceSansPro',
        'font-bold',
        'text-darkblue',
        'text-base'
      )
      clearBtn.style.margin = '1px 51px'
      container.appendChild(clearBtn)
      clearBtn.addEventListener('click', this.clean)

      return container
      //       return `<div><div class="font-SourceSansPro font-semibold mt-5 pb-4 text-textmain border-b">Линейное измерение</div>
      // <p class="text-someGrey font-semibold text-xs">${model.lengthDisplay}</p>
      // </div>`
    },
    areaPopupTemplateCompiled({ model }) {
      const container = document.createElement('div')
      const measureDiv = document.createElement('div')
      measureDiv.classList.add(
        'font-SourceSansPro',
        'font-semibold',
        'mt-5',
        'pb-4',
        'text-textmain'
      )

      const p = document.createElement('p')
      p.innerText = this.$t('measure.title')
      p.classList.add('border-b', 'border-normalButton', 'pb-3')
      measureDiv.appendChild(p)
      container.appendChild(measureDiv)
      const area = document.createElement('p')
      area.innerText = `${this.calced.areaDisplay} ${this.$t('measure.square')}`
      area.classList.add('text-someGrey', 'font-semibold', 'text-xs')

      const pirimeter = document.createElement('p')
      //   if(isNaN(this.calced.perimeter)){
      //     pirimeter.innerText = this.calced.perimeter
      //   }else{
      //   let permiterKm =this.calced.perimeter  / 1000

      //   pirimeter.innerText = `${this.calced.perimeter.toFixed(3)}м   (${permiterKm.toFixed(2)}км) Площадь`
      // }
      pirimeter.innerText = `${this.calced.lengthDisplay} ${this.$t(
        'measure.perimeter'
      )}`
      pirimeter.classList.add('text-someGrey', 'font-semibold', 'text-xs')
      measureDiv.appendChild(pirimeter)
      measureDiv.appendChild(area)
      const btnContainer = document.createElement('div')
      btnContainer.classList.add('w-full', 'flex')
      btnContainer.style.justifyContent = 'center'
      const clearBtn = document.createElement('button')
      clearBtn.innerText = this.$t('measure.clear')
      clearBtn.classList.add(
        'focus_outline-none',
        'font-SourceSansPro',
        'font-bold',
        'text-darkblue',
        'text-base'
      )

      btnContainer.appendChild(clearBtn)
      measureDiv.appendChild(btnContainer)
      clearBtn.addEventListener('click', this.clean)
      return container
    },
    clean(e) {
      this.FeatureGroup.eachLayer((layer) => {
        if (this.currentLayer._leaflet_id === layer._leaflet_id) {
          layer.removeFrom(this.map)
        }
        layer.removeFrom(this.map)
      })
      this.$store.commit('measure/MEASURE_CHANGE', false)
      this.$store.commit('measure/HANDLE_MEASURETYPE', 'polygon')
      this.$emit('closeMeasure')

      // this.resetMeasure()

      // this.$store.commit('measure/MEASURE_CHANGE', true)
      // this.removeAllResults()
      // this.finishMeasure()
      // this.map.removeLayer(this.mlayer)
      // this.mlatlngs = []
      // this.resultsModel = null
    }
  },
  mounted() {
    setTimeout(() => {
      this.FeatureGroup.addTo(this.map)
    }, 1000)
  }
}
