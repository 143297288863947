import L from 'leaflet'

const BingLayer = L.TileLayer.extend({
  getTileUrl(coords) {
    const quadkey = this.toQuadKey(coords.x, coords.y, coords.z)
    let url = L.Util.template(this._url, {
      q: quadkey,
      s: this._getSubdomain(coords)
    })
    if (typeof this.options.style === 'string') {
      url += `&st=${this.options.style}`
    }
    return url
  },
  toQuadKey(x, y, z) {
    let index = ''
    for (let i = z; i > 0; i--) {
      let b = 0
      const mask = 1 << (i - 1)
      if ((x & mask) !== 0) b++
      if ((y & mask) !== 0) b += 2
      index += b.toString()
    }
    return index
  }
})

export default {
  'Yandex Map': new L.TileLayer(
    'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}',
    // 'https://vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU',
    {
      subdomains: ['01', '02', '03', '04'],
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      minZoom: 0,
      reuseTiles: true,
      crs: L.CRS.EPSG3395,
      updateWhenIdle: false
    }
  ),
  // 'Yandex Traffic': new L.TileLayer(
  //   'https://core-renderer-tiles.maps.yandex.net/tiles&x={x}&y={y}&z={z}?l=map,trf',
  //   // 'https://static-maps.yandex.ru/1.x/?ll=37.620070,55.753630&spn=0.1,0.1&l=map,trf',
  //   {
  //    subdomains: ['01', '02', '03', '04'],
  //     attribution: '',
  //     maxZoom: 20,
  //     maxNativeZoom: 18,
  //     minZoom: 0,
  //     reuseTiles: true,
  //     crs: L.CRS.EPSG3395,
  //     updateWhenIdle: false
  //   }
  // ),
  'Google Traffic': new L.TileLayer(
    'https://mt0.google.com/vt/lyrs=m,traffic&hl=ru&x={x}&y={y}&z={z}',
    // 'https://vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU',
    {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  ),
  'Google Sattelite': new L.TileLayer(
    'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  ),
  'Google roadmap': new L.TileLayer(
    'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  ),
  'Google terrain': new L.TileLayer(
    'https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
    {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  ),
  'Google hybrid': new L.TileLayer(
    'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    {
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  ),
  OSM: new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '',
    maxZoom: 20,
    maxNativeZoom: 18,
    crs: L.CRS.EPSG3857,
    minZoom: 0
  }),
  Here: new L.TileLayer(
    'https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png8?app_id=LbHgOdNGDXxjxuJAgCRr&app_code=7XJbgjsTqMAziUnoy4GW-w&ppi=320',
    {
      maxZoom: 20,
      maxNativeZoom: 18,
      minZoom: 0,
      reuseTiles: false
    }
  ),
  Bing: new BingLayer(
    'http://ecn.{s}.tiles.virtualearth.net/tiles/r{q}.jpeg?g=7830&mkt=kk&shading=hill',
    {
      subdomains: ['t0', 't1', 't2', 't3', 't4'],
      maxZoom: 20,
      maxNativeZoom: 18,
      minZoom: 0,
      reuseTiles: false
    }
  ),
  'Bing Satellite': new BingLayer(
    'http://ecn.{s}.tiles.virtualearth.net/tiles/a{q}.jpeg?g=7830',
    {
      subdomains: ['t0', 't1', 't2', 't3', 't4'],
      maxZoom: 20,
      maxNativeZoom: 18,
      minZoom: 0,
      reuseTiles: false
    }
  )
  // 'Bing Satellite': new BingLayer(
  //   'http://ecn.{s}.tiles.virtualearth.net/tiles/a{q}.jpeg?g=7830',
  //   {
  //     subdomains: ['t0', 't1', 't2', 't3', 't4'],
  //     maxZoom: 20,
  //     maxNativeZoom: 18,
  //     minZoom: 0,
  //     reuseTiles: false
  //   }
  // )
}
