import { mapMutations, mapState } from 'vuex'
import L from '@/utils/leaflet.js'

export default {
  computed: {
    ...mapState('trackPlayer', {
      fitBoundsToTrack: (state) => state.fitBoundsToTrack,
      movingMarkerPlay: (state) => state.movingMarkerPlay,
      movingMarkerPause: (state) => state.movingMarkerPause,
      markerSpeed: (state) => state.markerSpeed,
      moveMarkerBack: (state) => state.moveMarkerBack
    }),
    ...mapState('map', {
      eyeTriggered: (state) => state.eyeTriggered.value,
      removeMovingMarkerId: (state) => state.removeMovingMarkerId
    })
  },
  data() {
    return {
      directiveShow: true,
      directiveDate: '',
      playMarkers: {},
      activePlayMarker: {
        lat: null,
        lng: null
      },
      speedInSelect: {},
      speedAtStart: {},
      trackId: '',
      annData: [],
      directionArrows: {}
    }
  },
  watch: {
    eyeTriggered(val) {
      if (val === 'all' || val === 'tracks') {
        this.CLEAR_TREK(true)
        this.DIRECTIVE_ZOOM(false)
        this.directiveShow = false
        this.removeDirectionArrow('all')
      }
    },
    removeMovingMarkerId(val) {
      this.trackId = null
      const trackID = Object.keys(val)[0]
      if (this.playMarkers && this.playMarkers[trackID]) {
        // this.removeDirectionArrow()
        this.playMarkers[trackID].removeFrom(this.map)
        delete this.playMarkers[trackID]
      }
    },
    fitBoundsToTrack(val) {
      // const trackID = Object.keys(val)[0]
      if(this.activePlayMarker.lat && this.activePlayMarker.lng) {
        this.map.panTo(new L.LatLng(this.activePlayMarker.lat, this.activePlayMarker.lng));
      }
    },
    movingMarkerPlay(val) {
      const trackID = Object.keys(val)[0]
      if (!Object.keys(this.activeTracksbyDate).includes(trackID)) return
      if (!this.speedInSelect[trackID]) this.speedInSelect[trackID] = 10
      if (this.playMarkers[trackID]) {
        if (this.playMarkers[trackID].isPaused()) {
          this.playMarkers[trackID].resume()
        } else {
          this.map.removeLayer(this.playMarkers[trackID])
          delete this.playMarkers[trackID]
          this.startPlayTrack(trackID, val.iconKey)
        }
      } else {
        this.startPlayTrack(trackID, val.iconKey)
      }
    },

    movingMarkerPause(val) {
      const trackID = Object.keys(val)[0]
      this.playMarkers[trackID] && this.playMarkers[trackID].pause()
    },

    markerSpeed(val) {
      const trackID = Object.keys(val)[0]
      const markerSpeed = Object.values(val)[0]
      this.speedInSelect[trackID] = markerSpeed
      if (
        this.playMarkers[trackID] &&
        !this.playMarkers[trackID].options.isMovingBack &&
        !this.playMarkers[trackID].isEnded()
      ) {
        const trackSpeed = markerSpeed / this.speedAtStart[trackID]
        this.playMarkers[trackID].setSpeed(trackSpeed)
      }
    },

    moveMarkerBack(val) {
      const trackID = Object.keys(val)[0]
      if (this.playMarkers[trackID]) {
        this.playMarkers[trackID].on('start', () => {
          this.playMarkers[trackID].options.isMovingBack = true
          this.playMarkers[trackID].unbindTooltip()
          this.$store.commit('trackPlayer/TRACK_FINISHED', {
            id: trackID,
            isMovingBack: true
          })
        })
        this.playMarkers[trackID].moveBack()
        this.playMarkers[trackID].on('end', () => {
          this.playMarkers[trackID].options.isMovingBack = false
          this.$store.commit('trackPlayer/TRACK_FINISHED', {
            id: trackID,
            isMovingBack: false
          })
        })
      }
    }
  },
  methods: {
    ...mapMutations('reports', ['CLEAR_TREK', 'DIRECTIVE_ZOOM']),
    removeDirectionArrow(value) {
      if (value && value === 'all') {
        this.listMarkers = {}
        if (this.directionArrows.reports) {
          this.directionArrows.reports.removeFrom(this.map)
        }
        if (this.directionArrows.monitoring) {
          this.directionArrows.monitoring.removeFrom(this.map)
        }
        if (this.directionArrows.reportsTrack) {
          this.directionArrows.reportsTrack.removeFrom(this.map)
        }
        this.directionArrows = {}
      } else {
        if (this.directionArrows.reports) {
          delete this.listMarkers.reports
          this.directionArrows.reports.removeFrom(this.map)
          delete this.directionArrows.reports
        }
        if (this.directionArrows.reportsTrack) {
          delete this.listMarkers.reportsTrack
          this.directionArrows.reportsTrack.removeFrom(this.map)
          delete this.directionArrows.reportsTrack
        }
      }
    },
    startPlayTrack(trackId, iconKey) {
      // найдем имя иконки
      const index = iconKey.lastIndexOf('_')
      const correctIconUrl = iconKey.substring(0, index)
      const arrIcon = correctIconUrl.split('/')
      const currentIcon = arrIcon.pop()

      const colors = [
        { name: 'red', color: '#eb5757' },
        { name: 'green', color: '#1bb934' },
        { name: 'blue', color: '#1585d8' },
        { name: 'grey', color: '#808080bf' },
        { name: 'orange', color: '#e68a2f' }
      ]

      // найдем цвет иконки
      const urlColor = iconKey.split('_')
      const clr = urlColor.pop()
      let currentColorIcon = ''

      colors.forEach((el) => {
        if (el.name === clr) {
          currentColorIcon = el.color
        }
      })

      this.removeDirectionArrow()
      this.directiveShow = false
      this.DIRECTIVE_ZOOM(false)
      const markerData = this.markerData[trackId]
      const trackPlayerIcon = L.divIcon({
        html: `<span class="travel-animation" style='padding-top:2px ;background-color:white; padding-bottom:10px; border-radius:50%; border: 2px solid ${currentColorIcon}'>
                    <svg width="35" height="55" viewBox="0 0 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <use xlink:href="/static/car_icon-new/cars.svg#${currentIcon}" fill="${currentColorIcon}"></use>
                    </svg>
                </span>
                `,
        iconSize: [45, 61],
        className: 'trackIcon',

        popupAnchor: [-3, -76]
      })
      const durations = Array(this.markerData[trackId].dates.length - 1)
        .fill(1000)
        .map((speed) => speed / this.speedInSelect[trackId])

      console.log('markerData', markerData)

      const playMarker = L.Marker.movingMarker(markerData.dates, durations, {
        icon: trackPlayerIcon,
        isMovingBack: false
      })
        .bindTooltip(

          `<div class="flex flex-col text-white font-bold my-2 mx-3 ">
            <div class="flex items-center">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8C0 3.58173 3.58173 0 8 0C12.4183 0 16 3.58173 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68628 14 2 11.3137 2 8C2 4.68628 4.68628 2 8 2C11.3137 2 14 4.68628 14 8ZM9 5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5V8C7 8.43043 7.27543 8.81257 7.68377 8.94868L10.6838 9.94868C11.2077 10.1233 11.774 9.84017 11.9487 9.31623C12.1233 8.79228 11.8402 8.22596 11.3162 8.05132L9 7.27924V5Z" fill="white"/>
              </svg>
              <div class="ml-3">${markerData.annData[0][0]}
              </div>
            </div>
            <div class="flex items-center">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.67908 13.3116 11.1956 12.1991 12.2858C11.8046 12.6723 11.7982 13.3054 12.1847 13.6999C12.5713 14.0944 13.2044 14.1008 13.5989 13.7142C15.0795 12.2634 16 10.2383 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 10.2383 0.920534 12.2634 2.40112 13.7142C2.79558 14.1008 3.42871 14.0944 3.81526 13.6999C4.2018 13.3054 4.19538 12.6723 3.80092 12.2858C2.6884 11.1956 2 9.67908 2 8ZM9.86817 8.75C10.1422 8.27048 10.9756 5.9062 10.4961 5.63219C10.0165 5.35818 8.40569 7.27074 8.13168 7.75026L7.13168 9.50429C6.85767 9.98381 7.02427 10.5947 7.50379 10.8687C7.98331 11.1427 8.59416 10.9761 8.86817 10.4966L9.86817 8.75Z" fill="white"/>
              </svg>
              <div class="ml-3">${markerData.annData[0][1]} км/ч</div>
            </div>
          </div>`,
          {
            permanent: true,
            direction: 'right',
            className: 'bg-black',
            offset: L.point(30, -40),
            opacity: 0.7
          }
        )
        .addTo(this.map)

      // Обработка события move
      const _self = this;
      playMarker.on('move', function(event){
        const newPosition = event.target.getLatLng();
        _self.activePlayMarker.lat = newPosition.lat;
        _self.activePlayMarker.lng = newPosition.lng;
      });

      this.speedAtStart = {
        ...this.speedAtStart,
        [trackId]: this.speedInSelect[trackId]
      }
      this.playMarkers = {
        ...this.playMarkers,
        [trackId]: playMarker
      }

      playMarker.start()
      playMarker.on('newline', (e) => {
        !playMarker.options.isMovingBack &&
          playMarker.setTooltipContent(
            `<div class="flex flex-col text-white font-bold my-2 mx-3">
            <div class="flex items-center">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8C0 3.58173 3.58173 0 8 0C12.4183 0 16 3.58173 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68628 14 2 11.3137 2 8C2 4.68628 4.68628 2 8 2C11.3137 2 14 4.68628 14 8ZM9 5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5V8C7 8.43043 7.27543 8.81257 7.68377 8.94868L10.6838 9.94868C11.2077 10.1233 11.774 9.84017 11.9487 9.31623C12.1233 8.79228 11.8402 8.22596 11.3162 8.05132L9 7.27924V5Z" fill="white"/>
              </svg>
              <div class="ml-3">${markerData.annData[e.index][0]}
              </div>
            </div>
            <div class="flex items-center">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.67908 13.3116 11.1956 12.1991 12.2858C11.8046 12.6723 11.7982 13.3054 12.1847 13.6999C12.5713 14.0944 13.2044 14.1008 13.5989 13.7142C15.0795 12.2634 16 10.2383 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 10.2383 0.920534 12.2634 2.40112 13.7142C2.79558 14.1008 3.42871 14.0944 3.81526 13.6999C4.2018 13.3054 4.19538 12.6723 3.80092 12.2858C2.6884 11.1956 2 9.67908 2 8ZM9.86817 8.75C10.1422 8.27048 10.9756 5.9062 10.4961 5.63219C10.0165 5.35818 8.40569 7.27074 8.13168 7.75026L7.13168 9.50429C6.85767 9.98381 7.02427 10.5947 7.50379 10.8687C7.98331 11.1427 8.59416 10.9761 8.86817 10.4966L9.86817 8.75Z" fill="white"/>
              </svg>
              <div class="ml-3">${markerData.annData[e.index][1]} км/ч</div>
            </div>
          </div>`
          )
      })
      playMarker.on('end', () => {
        !playMarker.options.isMovingBack &&
          playMarker.setTooltipContent(
            `<div class="flex flex-col text-white font-bold my-2 mx-3">
            <div class="flex items-center">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58173 16 0 12.4183 0 8C0 3.58173 3.58173 0 8 0C12.4183 0 16 3.58173 16 8ZM14 8C14 11.3137 11.3137 14 8 14C4.68628 14 2 11.3137 2 8C2 4.68628 4.68628 2 8 2C11.3137 2 14 4.68628 14 8ZM9 5C9 4.44772 8.55229 4 8 4C7.44772 4 7 4.44772 7 5V8C7 8.43043 7.27543 8.81257 7.68377 8.94868L10.6838 9.94868C11.2077 10.1233 11.774 9.84017 11.9487 9.31623C12.1233 8.79228 11.8402 8.22596 11.3162 8.05132L9 7.27924V5Z" fill="white"/>
              </svg>
              <div class="ml-3">${
                markerData.annData[markerData.annData.length - 1][0]
              }
              </div>
            </div>
            <div class="flex items-center">
              <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.67908 13.3116 11.1956 12.1991 12.2858C11.8046 12.6723 11.7982 13.3054 12.1847 13.6999C12.5713 14.0944 13.2044 14.1008 13.5989 13.7142C15.0795 12.2634 16 10.2383 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 10.2383 0.920534 12.2634 2.40112 13.7142C2.79558 14.1008 3.42871 14.0944 3.81526 13.6999C4.2018 13.3054 4.19538 12.6723 3.80092 12.2858C2.6884 11.1956 2 9.67908 2 8ZM9.86817 8.75C10.1422 8.27048 10.9756 5.9062 10.4961 5.63219C10.0165 5.35818 8.40569 7.27074 8.13168 7.75026L7.13168 9.50429C6.85767 9.98381 7.02427 10.5947 7.50379 10.8687C7.98331 11.1427 8.59416 10.9761 8.86817 10.4966L9.86817 8.75Z" fill="white"/>
              </svg>
              <div class="ml-3">${
                markerData.annData[markerData.annData.length - 1][1]
              } км/ч</div>
            </div>
          </div>`
          )

        this.$store.commit('trackPlayer/TRACK_FINISHED', {
          id: trackId,
          isMovingBack: false
        })
      })
    }
  }
}
