import L from '@/utils/leaflet.js'
import moment from 'moment'
import { mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      gottenNotifications: [],
      notificationMarkers: {},
      oldNotificationsOnMap: '',
      currentPopupMarker: []
    }
  },
  computed: {
    ...mapState('login', {
      changingCompany: (state) => state.changingCompany
    }),
    ...mapState('notifications', {
      notificationMarkerSetView: (state) => state.markerId
    }),
    ...mapState('map', {
      isHidingNotification: (state) => state.isHidingNotification
    })
  },

  watch: {
    changingCompany() {
      if (this.isHidingNotification) {
        this.resetNotificationMap()
      }
      this.map.closePopup()
    },
    notificationMarkerSetView(val) {
      const id = Object.keys(val)[0]
      this.notificationMarkers[id] &&
        this.map.setView(
          this.notificationMarkers[id].getLatLng(),
          this.map.getZoom()
        )
    },
    isHidingNotification(val) {
      if (val) {
        this.resetNotificationMap()
      } else {
        this.drawOldNotificationsOnMap(this.notificationMarkers)
      }
    }
  },
  methods: {
    ...mapMutations('map', ['SET_MARKER_NOTIFY_ANXIOUS', 'SET_MARKER_NOTIFY']),
    notifyCellClick(id, data) {
      this.currentPopupMarker.forEach((marker) => {
        if (this.map.hasLayer(marker)) {
          this.map.removeLayer(marker)
        }
      })
      const latlng = this.notificationMarkers[id].getLatLng()
      if (this.notificationMarkers[id]) {
        this.notificationMarkers[id].addTo(this.map)
        this.currentPopupMarker.push(this.notificationMarkers[id])
        this.notificationMarkers[id]
          .getPopup()
          .setLatLng(latlng)
          .openOn(this.map)
        this.map.setView(latlng, this.map.getZoom())
      } else {
        console.log(2)
        if (!this.setNotificationMarker) {
          console.log(3)
          this.setNotificationMarker(id, data)
          this.map.setView(latlng, this.map.getZoom())
          this.notificationMarkers[id]
            .getPopup()
            .setLatLng(latlng)
            .openOn(this.map)
        }
      }
    },

    setNotificationMarker(id, data) {
      const markerData = [data.latitude, data.longitude]
      const marker = L.marker(markerData, {
        icon: L.icon({
          iconUrl: `/static/notification_markers_front/${data.templateTypeKey}.svg`,
          iconSize: [28, 34],
          iconAnchor: [14, 34]
        }),
        key: data.templateTypeKey,
        isNotification: true
      }).bindPopup(
        `<div class="flex flex-col mt-2 mx-4 mb-4 kingslay">
          <div class="flex justify-between">
          <div class="font-bold text-headerText text-sm leading-none">${
            data.templateName
          }</div>
          <div class="ml-2 font-bold text-headerText text-xs whitespace-no-wrap" style="line-height: 1.5">${moment(
            data.time
          )
            .utc()
            .format('DD HH:mm:ss')}</div>
            </div>
            <div class="font-bold text-darkblue text-xs leading-none mt-3">${
              data.msg
            }</div>
            </div>`,
        {
          className: 'bg-white',
          offset: L.point(0, -25)
        }
      )

      this.notificationMarkers = {
        ...this.notificationMarkers,
        [id]: marker
      }

      if (!this.isHidingNotification) {
        if (data.templateTypeKey === 'alarm_notification') {
          this.SET_MARKER_NOTIFY_ANXIOUS(marker)
          this.markerClusterNotifyAnxious.addLayer(marker)
        } else {
          this.SET_MARKER_NOTIFY(marker)
          this.markerClusterNotify.addLayer(marker)
        }
      }
    },

    resetNotificationMap() {
      if (this.notificationMarkers) {
        this.oldNotificationsOnMap = this.notificationMarkers
        Object.values(this.notificationMarkers).forEach((marker) => {
          if (this.markerClusterNotifyAnxious.hasLayer(marker)) {
            this.markerClusterNotifyAnxious.removeLayer(marker)
          } else {
            this.markerClusterNotify.removeLayer(marker)
          }
        })
      }
    },

    drawOldNotificationsOnMap(notifications) {
      this.notificationMarkers = notifications
      Object.values(this.notificationMarkers).forEach((marker) => {
        marker.isNotif = true
        if (marker.options.key === 'alarm_notification') {
          this.markerClusterNotifyAnxious.addLayer(marker)
        } else {
          this.markerClusterNotify.addLayer(marker)
        }
      })
      this.notificationMarkers = notifications
    }
  }
}
