import L from 'leaflet'
const currentUrl = window.location.href

export default {
  Temperature: new L.TileLayer(
    `${currentUrl}map/temp_new/{z}/{x}/{y}.png`,
    // {
    //   attribution: '',
    //   maxZoom: 20,
    //   maxNativeZoom: 18,
    //   crs: L.CRS.EPSG3857,
    //   minZoom: 0
    // }
  ),
  Clouds: new L.TileLayer(
    // `${devUrl}/map/clouds_new/{z}/{x}/{y}.png`,
    'https://tile.openweathermap.org/map/clouds_new/{z}/{x}/{y}.png?appid=4b402ee1d71951f9826c6950ad8fcf5d',
    {
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  ),
  Wind: new L.TileLayer(
    'https://tile.openweathermap.org/map/wind_new/{z}/{x}/{y}.png?appid=4b402ee1d71951f9826c6950ad8fcf5d',
    {
      attribution: '',
      maxZoom: 20,
      maxNativeZoom: 18,
      crs: L.CRS.EPSG3857,
      minZoom: 0
    }
  )
}
