import { typesFields } from '@/utils/events.js'
import L from '@/utils/leaflet.js'
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'

const submitDateTimeFormat = 'yyyy-MM-DD HH:mm:ss'

export default {
  data() {
    return {
      eventsMarkers: {},
      toggleEvents: false
    }
  },
  computed: {
    ...mapGetters({
      login: 'login/changingCompany',
      eventsMapItems: 'events/mapItems',
      event: 'events/event',
      updateEvent: 'events/updateEvent',
      deleteEvent: 'events/deleteEvent'
    }),
    ...mapState('map', {
      isHidingEvents: (state) => state.isHidingEvents,
      companyChange: (state) => state.companyChange
    }),
    ...mapState('dictionary', {
      eventTypes: (state) => state.event_type
    }),
    ...mapState('units', {
      eventsUnits: (state) => state.units
    }),
    ...mapState('users', {
      users: (state) => state.users
    }),
    ...mapState('login', {
      isNeedChangePassword: (state) => state.isNeedChangePassword
    })
  },
  watch: {
    isNeedChangePassword(val) {
      if (!val) {
        this.handleLogin()
      }
    },
    // isHidingEvents(val) {
    //   console.log('changed', val)
    // },
    companyChange() {
      this.eventsClearMarkers()
    },
    eventsMapItems() {
      // this.markerClusterEvents.clearLayers()
    },
    login() {
      this.handleLogin()
    },
    isHidingEvents: {
      handler(val) {
        this.eventsToggleShow(val)
      },
      immediate: false
    },
    eventsUnits(val) {
      this.eventsSetMarkersUnitNames(val)
    },
    // если новый обьект добавляем
    event(newVal) {
      if (newVal) {
        this.eventsCreateMarker(newVal)
      }
    },
    // если обьект обновляем
    updateEvent(newVal) {
      if (newVal) {
        this.eventsUpdateMarker(newVal)
      }
    },
    deleteEvent(newVal) {
      if (newVal) {
        for (const i in this.eventsMarkers) {
          if (this.eventsMarkers[i].data.id === newVal) {
            this.markerClusterEvents.removeLayer(this.eventsMarkers[i])
          }
        }
      }
    }
  },
  methods: {
    handleLogin() {
      const toggleEvents = JSON.parse(localStorage.getItem('hideevents'))
      if (toggleEvents) {
        this.eventsClearMarkers()
      } else {
        this.eventsInitMarkers()
      }
    },
    async eventsGetAllItems() {
      const dateTo = moment(Date.now()).format(submitDateTimeFormat)
      const dateFrom = moment(Date.now())
        .subtract(1, 'day')
        .format(submitDateTimeFormat)
      const data = {
        from: 0,
        count: 100,
        conditions: [
          {
            field: 'date_from',
            value: dateFrom
          },
          {
            field: 'date_to',
            value: dateTo
          }
        ]
      }
      if (this.isNeedChangePassword) {
        return
      }
      await this.$store.dispatch('events/GET_MAP_ITEMS', data)
    },
    eventsCreateMarkers(events) {
      events.forEach((item) => {
        this.eventsCreateMarker(item)
      })
    },
    eventsCreateMarkerIconHtml(iconUrl) {
      return `<img class='skif-event-marker__img66' src='${iconUrl}' alt=''>`
    },
    eventsGetUnitNameById(id) {
      const unit = this.eventsUnits.find((item) => item.id === id)
      return unit && unit.name ? unit.name : id
    },
    eventsCreateMarkerPopup(data, type) {
      const fields = typesFields[type] || []
      const unitId = data.unit.id
      const unit = this.eventsGetUnitNameById(unitId)
      const eventObj = this.eventTypes.find((item) => item.key === type)
      const event = eventObj && eventObj.value ? eventObj.value : type
      const rows = [
        { label: this.$t('name'), value: unit },
        { label: this.$t('events'), value: event }
      ]
      const self = this
      Object.keys(data).forEach((key) => {
        if (key !== 'user_id') {
          if (fields.includes(key)) {
            const label = this.$t(`events.${key}`)
            const value = data[key]
            rows.push({ label, value })
          }
        } else if (key === 'user_id') {
          let value
          self.users.forEach((unit) => {
            if (unit.id === data[key]) {
              value = unit.name
            }
          })
          const label = this.$t(`events.${key}`)
          rows.push({ label, value })
        }
      })
      let rowsHtml = ''
      const getValue = (row) => {
        const res =
          row.value && typeof row.value === 'boolean'
            ? row.value
              ? this.$t('yes')
              : this.$t('no')
            : row.value
            ? row.value
            : this.$t('no')
        return res
      }

      rows.forEach((row) => {
        rowsHtml += `<tr class="border-b last_border-b-0">
          <td class="px-2 py-1 text-annotationColor font-semibold border-l-0 border-r-0 font-SourceSansPro text-base">
            ${row.label}:
          </td>
          <td class="px-2 py-1 text-darkblue font-bold border-l-0 border-r-0 text-sm" style='word-break:break-all'>
            ${getValue(row)}
          </td>
        </tr>`
      })
      return `<table class="table-auto m-2 border-collapse"><tbody>${rowsHtml}</tbody></table>`
    },
    eventsSetMarkersUnitNames(units) {
      if (!units || !units.length) return

      Object.keys(this.eventsMarkers).forEach((key) => {
        const marker = this.eventsMarkers[key]
        this.eventsMarkerBindPopup(marker)
      })
    },
    eventsMarkerBindPopup(marker) {
      const { data } = marker
      const type = data && data.type && data.type.key ? data.type.key : ''
      marker.bindPopup(this.eventsCreateMarkerPopup(data, type), {
        maxWidth: 360,
        className: 'bg-white',
        offset: L.point(0, -25)
      })
    },

    // продумать, когда будет время, как отрефакторить добавление/редактирование/удаление событий на карте
    updateMarker(marker) {
      const data = marker
      const type = data && data.type && data.type.key ? data.type.key : ''

      this.eventsCreateMarkerPopup(data, type)
    },
    eventsUpdateMarker(marker) {
      const data = marker
      const type = data && data.type && data.type.key ? data.type.key : ''

      for (const value in this.eventsMarkers) {
        if (this.eventsMarkers[value].data.id === marker.id) {
          this.eventsMarkers[value].data = marker
          // обновляем контент в попапе
          this.eventsMarkers[value]._popup.setContent(
            this.eventsCreateMarkerPopup(this.eventsMarkers[value].data, type)
          )
        }
      }
    },
    eventsCreateMarker(data) {
      if (data.type.key === 'waybill') return
      const { id } = data
      const type = data && data.type && data.type.key ? data.type.key : ''
      const iconUrl = `static/events_markers_front/${type}.svg`
      const icon = L.divIcon({
        // iconSize: [66, 66],
        // iconAnchor: [13, 36],
        popupAnchor: [13, 36],
        className: 'skif-event-markeqr',
        html: this.eventsCreateMarkerIconHtml(iconUrl)
      })
      const markerData = [data.latitude, data.longitude]
      const marker = L.marker(markerData, {
        icon,
        draggable: false
      })
      marker.data = data
      this.eventsMarkerBindPopup(marker)

      this.markerClusterEvents.addLayer(marker)
      this.eventsMarkers = {
        ...this.eventsMarkers,
        [id]: marker
      }
    },
    eventsToggleShow(hide) {
      if (hide) {
        this.eventsClearMarkers()
      } else {
        this.eventsInitMarkers()
      }
    },
    async eventsInitMarkers() {
      await this.eventsGetAllItems()
      this.markerClusterEvents.clearLayers()
      this.eventsCreateMarkers(this.eventsMapItems)
    },
    eventsClearMarkers() {
      if (this.eventsMarkers) {
        this.markerClusterEvents.clearLayers()
        this.eventsMarkers = {}
        this.$store.commit('events/SET_MAP_ITEMS', [])
      }
    }
  },
  beforeDestroy() {
    this.eventsClearMarkers()
  },
  async created() {
    setTimeout(() => {
      this.toggleEvents = JSON.parse(localStorage.getItem('hideevents'))
      if (!this.toggleEvents) {
        this.eventsInitMarkers()
      }
    }, 1000)
  }
}
