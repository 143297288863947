// import { mapState } from 'vuex'

// import L from '@/utils/leaflet.js'

export default {
  
  
  methods: {
    changeChecked($event) {
     
      let markers = []
      const isChecked = $event.target.checked
      // let currentmap = this.$parent.$refs['leaflet-map']

      if (isChecked) {
        this.sortedRows.forEach((row) => {
          const latlng = row[`_marker_latlon_${this.tableType}`]

          markers.push(latlng)
        })
      }
      console.log(this.map)
      // let layerGroup = L.layerGroup(markers)
      
    },
    
  },
}
